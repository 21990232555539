$('#gallery-main-image').magnificPopup({
  delegate: 'a',
  type: 'image',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1]
  }
});

$('#gallery-main-image').slick({
  adaptiveHeight: false,
  arrows: false,
  asNavFor: '#gallery-thumbs',
  fade: true,
  lazyLoad: 'ondemand'
});
$('#gallery-thumbs').slick({
  arrows: false,
  asNavFor: '#gallery-main-image',
  focusOnSelect: true,
  infinite: false,
  slidesToShow: 5,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        dots: true,
        slidesToScroll: 3,
        slidesToShow: 3
      }
    }
  ]
});


// Instafeed Widget
if ($("#gallery #instafeed").length > 0) {
      var feed = new Instafeed({
          clienID: "58d20c6a63ca4662b1b667f5d69b5092",
          get: "user",
          userId: 4091568143,
          accessToken: "4091568143.1677ed0.4ba1ee7661f7481ea08fc09fd3a264c4",
          limit: 100,
          resolution: "standard_resolution",
        template: "<div class=\"column\"><a href=\"{{image}}\" class=\"instainfo\" target=\"_blank\" data-caption=\"{{caption}}\" data-username=\"{{model.user.username}}\" data-profilepic=\"{{model.user.profile_picture}}\" data-bio=\"{{model.user.bio}}\" data-link=\"{{link}}\" style=\"background-image:url('{{image}}');\"></a></div>",
        after: function() {
            $('.instainfo').click(function instaPopup (e){
                // prevent hyperlink
                e.preventDefault();
                // setup variables from instagram data
                var $this = $(this);
                var $getUsername = $this.data('username');
                var $getCaption = $this.data('caption');
                var $getProfilePic = $this.data('profilepic');
                var $getBio = $this.data('bio');
                var $getLink = $this.data('link');
                var $getInstagramLink = '//www.instagram.com/ewheeldeal/';
                var $getImage = $this.attr('href');
                
                // implement into popup
                $('#instagram-popup')
                    .find('.instagram-popup-profilepic')
                    .attr('src', $getProfilePic);
                $('#instagram-popup')
                    .find('.instagram-popup-page')
                    .attr('href',$getInstagramLink);
                $('#instagram-popup')
                    .find('.instagram-popup-username')
                    .html($getUsername)
                    .attr('href',$getInstagramLink);
                $('#instagram-popup')
                    .find('.instagram-popup-link')
                    .attr('href',$getLink);
                $('#instagram-popup')
                    .find('.instagram-popup-img')
                    .attr('src',$getImage);
                $('#instagram-popup')
                    .find('.instagram-popup-caption')
                    .html($getCaption);
                
                // run magnific popup with variables set
                $.magnificPopup.open({
                    /*items:items,*/
                    items:{
                        src: '#instagram-popup',
                        type: 'inline'
                    },
                    gallery: {
                      enabled: true,
                      navigateByImgClick: true,
                      preload: [0, 1]
                    }
                });
            });
        }
      });
      feed.run();
        console.log(feed);
  }

// instgram gallery show more
if($('.hide-extra').length) {
  // load more button
  var $loadButton = $('.navigation');
  
  $('.navigation a').click(function(){
    // find hidden images
    var $hiddenImages = $('.hide-extra');
    var $hiddenImagesLength = $hiddenImages.length;
    var $displayNext = 24;
    
    // display next set of hidden images
    for(var $i=0; $i < $displayNext; $i++) {
      $($hiddenImages[$i]).removeClass('hide-extra');
    }
    
    // check if all hidden images are displayed
    var $checkHiddenImages = $('.hide-extra').length;
    if ($checkHiddenImages <= 0) {
      $($loadButton).hide();
    }
  });
}